import React from 'react'
import Avatar from '@mui/material/Avatar'
import { ListItem, ListItemIcon, ListItemText, ThemeProvider, Typography, ListItemButton } from '@mui/material'
import Divider from '@mui/material/Divider'
import List from '@mui/material/List'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined'
import LogoutIcon from '@mui/icons-material/Logout'
import { useNavigate } from 'react-router-dom'
import JumboDdPopover from '@jumbo/components/JumboDdPopover'
import Div from '@jumbo/shared/Div'
import useJumboTheme from '@jumbo/hooks/useJumboTheme'
import useJumboAuth from '@jumbo/hooks/useJumboAuth'

const LoginUserAvatar = () => {
    const navigate = useNavigate()
    const { theme } = useJumboTheme()
    const { authUser } = useJumboAuth()

    const onLogout = () => {
        navigate('/profile/signout')
    }

    const onEditProfile = () => {
        navigate('/profile/editprofile')
    }

    return (
        <ThemeProvider theme={theme}>
            <Div sx={{ ml: 'auto', display: 'flex' }}>
                <List
                    disablePadding
                    sx={{
                        margin: (theme) => theme.spacing(0, -2),
                        marginRight: '-90px',
                    }}>
                    <ListItem
                        sx={{
                            width: '100%',
                        }}>
                        <ListItemText
                            primary={
                                <Typography
                                    fontSize={'12px'}
                                    variant='h6'
                                    color='text.secondary'
                                    sx={{ width: 'max-content' }}
                                    mb={0.5}>
                                    {authUser?.displayName}
                                </Typography>
                            }
                            secondary={
                                <Typography variant='body1' color='text.primary' sx={{ width: 'max-content' }}>
                                    {authUser?.email}
                                </Typography>
                            }
                        />
                    </ListItem>
                </List>
                <JumboDdPopover
                    triggerButton={
                        <Avatar
                            src={authUser?.profile_pic || ''}
                            sx={{ boxShadow: 3, cursor: 'pointer', ml: 13, width: '39px', mt: '12px' }}
                        />
                    }
                    sx={{
                        '.MuiPopover-paper': {
                            right: '0 !important',
                            transform: 'translateX(0)',
                            maxWidth: '200px',
                        },
                    }}>
                    <Div
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            p: (theme) => theme.spacing(2),
                        }}>
                        <Avatar
                            src={authUser?.profile_pic || ''}
                            alt={authUser?.user || 'User'}
                            sx={{ width: 50, height: 50, mb: 2 }}
                        />
                        <Typography variant={'h5'}>{authUser?.userName || 'User'}</Typography>
                        <Typography variant={'body1'} color='text.secondary'></Typography>
                    </Div>
                    <Divider />
                    <nav>
                        <List disablePadding sx={{ pb: 1 }}>
                            <ListItemButton onClick={onEditProfile}>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <EditOutlinedIcon />
                                </ListItemIcon>
                                <ListItemText primary='Edit Profile' sx={{ my: 0 }} />
                            </ListItemButton>
                            <ListItemButton onClick={onLogout}>
                                <ListItemIcon sx={{ minWidth: 36 }}>
                                    <LogoutIcon />
                                </ListItemIcon>
                                <ListItemText primary='Logout' sx={{ my: 0 }} />
                            </ListItemButton>
                        </List>
                    </nav>
                </JumboDdPopover>
            </Div>
        </ThemeProvider>
    )
}

export default LoginUserAvatar
