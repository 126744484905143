import React from 'react'
import useJumboLayoutSidebar from '@jumbo/hooks/useJumboLayoutSidebar'
import { IconButton } from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import { SIDEBAR_STYLES, SIDEBAR_VARIANTS } from '@jumbo/utils/constants'
import LoginUserAvatar from './components/LoginUserAvatar'

const Header = () => {
    const { sidebarOptions, setSidebarOptions } = useJumboLayoutSidebar()

    return (
        <React.Fragment>
            {(sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ||
                sidebarOptions.variant === SIDEBAR_VARIANTS.TEMPORARY ||
                (sidebarOptions.variant === SIDEBAR_VARIANTS.PERSISTENT && !sidebarOptions.open)) && (
                <IconButton
                    edge='start'
                    color='inherit'
                    aria-label='open drawer'
                    sx={{
                        ml: sidebarOptions.style === SIDEBAR_STYLES.CLIPPED_UNDER_HEADER ? -2 : 0,
                        mr: 3,
                    }}
                    onClick={() => setSidebarOptions({ open: !sidebarOptions.open })}>
                    {sidebarOptions?.open ? <MenuOpenIcon /> : <MenuIcon />}
                </IconButton>
            )}
            {<LoginUserAvatar />}
        </React.Fragment>
    )
}

export default Header
